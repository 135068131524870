<template>
  <div
    class="plat-adv-container"
    v-lazy:background-image="{src: require('../../../assets/images/bg4.png')}"
  >
    <div class="page-outter">
      <div align="center">
        <img
          class="plat-adv-title"
          v-lazy="require('../../../assets/images/title10.png')"
        />
        <span class="plat-adv-desc">以平台串联产业链，以数据构建药品流转过程及节点全貌</span>
        <div class="plat-adv-inner">
          <ul>
            <li
              v-for="(item,index) in tds"
              :key="index"
            >
              <div class="plat-adv-dd">
                <div class="plat-adv-item">
                  <img v-lazy="item.icon" />
                </div>
                <span class="plat-adv-item-title">
                  {{item.title}}
                </span>
                <span
                  class="plat-adv-item-s1"
                  v-html="item.name"
                ></span>
              </div>
            </li>
          </ul>
          <div style="clear: both"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlatformAdvantage-spf",
  data() {
    return {
      tds: [
        {
          icon: require("../../../assets/images/app-icon10.png"),
          title: "线上供应链",
          name: `帮助医药生产厂家开拓线上渠道<br>直面用户，掌握市场动向<br>提升销量`,
        },
        {
          icon: require("../../../assets/images/app-icon4.png"),
          title: "大数据沉淀及分析",
          name: `完成药品流转等信息积累<br>生成营销数据报告<br>深度洞察用户数据<br>实现科学决策`,
        },
        {
          icon: require("../../../assets/images/app-icon11.png"),
          title: "数字化管理",
          name: `区块链药品码可精准记录<br>从生产到销售的所有数据<br>便于防伪和跟踪`,
        },
        {
          icon: require("../../../assets/images/app-icon12.png"),
          title: "打通信息渠道",
          name: `首营资料交换、药理说明<br>药品处理机制等产品及<br>企业信息一键送达到终端<br>提升信息透明度及反馈效率`,
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.plat-adv-container {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  width: 100%;
  padding-bottom: 2.4rem;
  background-repeat: no-repeat;
  background-size: cover;
}
.plat-adv-title {
  width: 7rem;
  margin-top: 2.4rem;
}
.plat-adv-desc {
  display: block;
  font-size: 0.3rem;
  color: #d7d8d7;
  margin-top: 0.2rem;
}
.plat-adv-inner {
  display: flex;
  justify-content: center;
  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    margin-left: -0.8rem;
    li {
      float: left;
      margin-left: 0.8rem;
      margin-top: 1.8rem;
    }
  }
}
.plat-adv-dd {
  width: 5.8rem;
}
.plat-adv-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.4rem;
  height: 2.4rem;
  border: 1px solid #dcdadd;
  border-radius: 2.4rem;
  img {
    width: 1.6rem;
  }
}
.plat-adv-item-title {
  display: block;
  font-size: 0.4rem;
  color: #fff;
  margin-top: 0.45rem;
}
.plat-adv-item-s1 {
  display: block;
  font-size: 0.36rem;
  color: #dcdadd;
  line-height: 1.7;
  margin-top: 0.26rem;
}
</style>